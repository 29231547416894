import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonToolbar,
} from '@ionic/react'
import { alertCircle } from 'ionicons/icons'
import { SyntheticEvent, useState } from 'react'
import { useHistory } from 'react-router'
import { useMutation } from 'urql'
import './Login.css'

const Login = () => {
  const [isLoading, setIsLoding] = useState(false)
  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [errors, setErrors] = useState<any>([])

  const MUTATION_LOGIN = `
    mutation ($username: String!, $password: String!) {
        tokenAuth (username: $username, password: $password) {
            token
            user {
                username,
                groups {
                    name
                }
            }
        }
    }`
  const [state, executeMutation] = useMutation(MUTATION_LOGIN)
  const history = useHistory()

  const handleSubmit = (e: SyntheticEvent) => {
    setIsLoding(true)
    e.preventDefault()

    executeMutation({
      password: password,
      username: username,
    }).then((response) => {
      if (response.error) {
        setErrors(response.error.graphQLErrors)
        setIsLoding(false)

        return
      }

      if (response.data.tokenAuth) {
        localStorage.setItem('auth-token', response.data.tokenAuth.token)
        localStorage.setItem('auth-username', response.data.tokenAuth.user.username)

        const groups = response.data.tokenAuth.user.groups

        if (groups.length) {
          let auth_group: any = []

          groups.map((group: any) => {
            auth_group.push(group.name)
          })

          localStorage.setItem('auth-group', auth_group)
        }

        setUsername('')
        setPassword('')
        setIsLoding(false)
        setErrors([])
        history.push('/')
      }
    })
  }

  const isAuth = !!localStorage.getItem('auth-token')
  return (
    <IonPage>
      <IonContent className="ion-padding">
        <IonGrid className="ion-padding ion-padding-top">
          <IonRow className="ion-justify-content-center ion-padding-top">
            <IonCol size="3" className="light-mode">
              <IonImg src="/assets/logo.png" />
            </IonCol>
            <IonCol size="5" className="dark-mode">
              <IonImg src="/assets/logo.png" />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-padding-bottom light-mode">
            <IonCol size="4" className="ion-text-center">
              {/* <IonImg src="/assets/logo_name.png" /> */}
            </IonCol>
          </IonRow>
          {isAuth ? (
            <>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" className="ion-text-center">
                  <IonLabel style={{ fontWeight: 700, fontSize: '1.5rem' }}>Connecté</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" className="ion-text-center">
                  <IonText style={{ fontSize: '0.8rem' }}>Vous êtes déjà connecté</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="6">
                  <IonButton onClick={(e) => history.push('/')}>Aller au Tableau de bord</IonButton>
                </IonCol>
              </IonRow>
            </>
          ) : (
            <>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" className="ion-text-center">
                  <IonLabel style={{ fontWeight: 700, fontSize: '1.5rem' }}>Connexion</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" className="ion-text-center">
                  <IonText style={{ fontSize: '0.8rem' }}>Connexion à l'espace de gestion</IonText>
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
        {isAuth ? (
          <></>
        ) : (
          <form onSubmit={handleSubmit}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel position="stacked" style={{ fontWeight: 700 }}>
                    Nom d'utilisateur
                  </IonLabel>
                  <IonInput
                    required
                    value={username}
                    onIonChange={(e) => setUsername(e.detail.value!)}
                    type="text"
                    placeholder="Entrer le nom d'utilisateur"
                    className="ion-padding"
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: 5,
                      marginTop: '0.5rem',
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-padding-top">
                  <IonLabel position="stacked" style={{ fontWeight: 700 }}>
                    Mot de passe
                  </IonLabel>
                  <IonInput
                    required
                    value={password}
                    onIonChange={(e) => setPassword(e.detail.value!)}
                    type="password"
                    placeholder="Entrer le mot de passe"
                    className="ion-padding"
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: 5,
                      marginTop: '0.5rem',
                    }}
                  />
                </IonCol>
              </IonRow>
              {errors.map((error: any, index: number) => (
                <IonRow key={index}>
                  <IonCol size="1" className="ion-align-self-center">
                    <IonIcon icon={alertCircle} size="large" color="danger" />
                  </IonCol>
                  <IonCol size="10" className="ion-align-self-center">
                    <IonLabel color="danger" className="ion-text-wrap ion-padding-start">
                      {error.message}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              ))}
              <IonRow className="ion-padding-top ion-justify-content-end">
                <IonCol size="4">
                  <IonButton className="ion-padding-horizental" expand="block" type="submit">
                    <IonSpinner
                      style={{
                        display: isLoading ? 'block' : 'none',
                      }}
                    />
                    <IonText
                      style={{
                        display: isLoading ? 'none' : 'block',
                      }}
                    >
                      Connexion
                    </IonText>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        )}
      </IonContent>
      <IonFooter collapse="fade">
        <IonToolbar className="ion-text-center">
          <IonLabel style={{ fontWeight: 600, fontSize: '0.8rem' }}>© QlikData</IonLabel>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default Login
