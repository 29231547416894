import {
  IonContent,
  IonHeader,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonBadge,
  IonButtons,
  IonBackButton,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
  IonIcon,
  IonText,
  IonProgressBar,
  useIonAlert,
  IonActionSheet,
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/react'
import {
  checkmarkCircle,
  close,
  closeCircle,
  exitOutline,
  removeCircle,
  restaurant,
} from 'ionicons/icons'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import BookingTagIcon from '../components/BookingTagIcon'
import './Booking.css'

const BOOKING_QUERY = `
  query ($date: Date!, $status: String, $placeId: Int, $complexId: Int) {
    bookings (date: $date, status: $status, placeId: $placeId, complexId: $complexId) {
        edges {
            node {
                id
                date
                time
                people
                status
                comment
                place {
                  name
                }
                zone {
                  name
                }
                tags {
                  name
                  icon
                  color
                }
                customer {
                    name
                    telephone
                }
            }
        }
    }
  }
`

interface Booking {
  node: {
    id: number
    comment: string
    people: number
    time: string
    date: string
    status: string
    place: {
      name: string
    }
    zone: {
      name: string
    }
    tags: [
      {
        name: string
        icon: string
        color: string
      }
    ]
    customer: {
      name: string
      telephone: string
    }
  }
}

const Booking: React.FC = () => {
  const { bookingStatus, date, placeId, complexId, pageTitle }: any = useParams()

  let variables = {
    date: date,
    status: bookingStatus === 'all' ? null : bookingStatus,
    placeId: placeId === 'null' ? null : placeId,
    complexId: complexId,
  }

  const [result, reexecuteQuery] = useQuery({
    query: BOOKING_QUERY,
    variables: variables,
  })

  const { data, fetching, error } = result

  const [isReferesher, setisRefresher] = useState(false)
  const [progressBarDisplay, setProgressBarDisplay] = useState('none')

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setisRefresher(true)
    reexecuteQuery({ requestPolicy: 'network-only' })

    setTimeout(() => {
      event.detail.complete()
    }, 2000)
  }

  const refresh = () => {
    reexecuteQuery({ requestPolicy: 'network-only' })
  }

  const [present] = useIonAlert()

  // useEffect(() => {
  //   if (!fetching) setProgressBarDisplay('none')

  //   const timerId = setTimeout(() => {
  //     setisRefresher(true)
  //     setProgressBarDisplay('none')
  //     reexecuteQuery({ requestPolicy: 'network-only' });
  //   }, 10000);

  //   return () => {
  //     clearTimeout(timerId)
  //   }
  // }, [result.fetching, reexecuteQuery]);

  const [showRefuseAction, setShowRefuseAction] = useState(false)
  const [showAcceptAction, setShowAcceptAction] = useState(false)
  const [showConfirmAction, setShowConfirmAction] = useState(false)
  const [showSeatAction, setShowSeatAction] = useState(false)
  const [showFinishAction, setShowFinishAction] = useState(false)
  const [showCancelAction, setShowCancelAction] = useState(false)
  const [currentSearch, setCurrentSearch] = useState('')

  const searchBooking = (booking: any) => {
    if (currentSearch === '') return booking

    let customer = booking.node.customer
    let telephone = customer.telephone.toLowerCase()
    let searchable = customer.name.toLowerCase() + telephone + telephone.replace(/\s/g, '')

    return searchable.includes(currentSearch.toLowerCase())
  }

  const [customerName, setCustomerName] = useState<string>()
  const [bookingToChange, setBookingToChange] = useState<any>()

  const handleStatusBooking = (bookingId: number, status: string, customerName: string) => {
    setCustomerName(customerName)
    setBookingToChange({ bookingId: bookingId, status: status })

    switch (status) {
      case 'accepted':
        setShowAcceptAction(true)
        break
      case 'seated':
        setShowSeatAction(true)
        break
      case 'finished':
        setShowFinishAction(true)
        break
      case 'refused':
        setShowRefuseAction(true)
        break
      case 'canceled':
        setShowCancelAction(true)
        break
      case 'confirmed':
        setShowConfirmAction(true)
        break
    }
  }

  const MUTATION_CHANGE_STATUS = `
      mutation($bookingId: Int!, $status: String!) {
        bookingChangeStatus(bookingId: $bookingId, status: $status) {
          booking {
            id
            status
          }
        }
      }
    `
  const [state, changeStatusMutation] = useMutation(MUTATION_CHANGE_STATUS)

  const handleChangeStatus = () => {
    changeStatusMutation({
      bookingId: bookingToChange.bookingId,
      status: bookingToChange.status,
    }).then((result) => {
      if (result.error) {
        present({
          message: result.error.graphQLErrors[0].message,
          buttons: [{ text: 'OK', role: 'cancel' }],
        })
      }

      closeItemSliding()
    })
  }

  const STATUS_COLOR: any = {
    all: 'primary',
    confirmed: 'primary',
    refused: 'danger',
    accepted: 'success',
    waiting: 'dark',
    no_show: 'dark',
    canceled: 'danger',
    finished: 'dark',
  }

  const ACTIONS: any = {
    confirmed: [
      { side: 'start', title: 'Refuser', status: 'refused', icon: removeCircle, color: 'danger' },
      {
        side: 'end',
        title: 'Accepter',
        status: 'accepted',
        icon: checkmarkCircle,
        color: 'success',
      },
    ],
    // 'accepted':  [
    //   {side: 'start', title: 'Cancel', status: "canceled", icon: closeCircle, color: "danger"},
    //   {side: 'end', title: 'Seat', status: "seated", icon: restaurant, color: "success"}
    // ],
    accepted: [],
    seated: [
      { side: 'start', title: 'Annuler', status: 'canceled', icon: closeCircle, color: 'danger' },
      { side: 'end', title: 'Terminer', status: 'finished', icon: exitOutline, color: 'medium' },
    ],
    waiting: [
      { side: 'start', title: 'Annuler', status: 'canceled', icon: closeCircle, color: 'danger' },
      {
        side: 'end',
        title: 'Confirmer',
        status: 'confirmed',
        icon: checkmarkCircle,
        color: 'success',
      },
    ],
    finished: [],
    refused: [],
    no_show: [
      { side: 'start', title: 'Refuse', status: 'refused', icon: removeCircle, color: 'danger' },
      { side: 'end', title: 'Accept', status: 'accepted', icon: checkmarkCircle, color: 'success' },
    ],
    canceled: [],
    no_booking: [],
  }

  const closeItemSliding = () => {
    const itemSliding = document.querySelector('ion-item-sliding') || null
    if (itemSliding) itemSliding.closeOpened()
  }

  return (
    <IonPage>
      <IonHeader collapse="fade">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonTitle
            size="large"
            color={!pageTitle && STATUS_COLOR[bookingStatus]}
            style={{ textTransform: 'capitalize' }}
          >
            {pageTitle ? pageTitle : bookingStatus}
          </IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            onIonChange={(e: any) => setCurrentSearch(e.detail.value)}
            value={currentSearch}
            placeholder="Search by name or phone"
          />
        </IonToolbar>
        <IonProgressBar
          type="indeterminate"
          style={{ display: progressBarDisplay }}
        ></IonProgressBar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonActionSheet
          backdropDismiss={false}
          header="Souhaitez-vous refuser cette réservation ?"
          subHeader={customerName}
          isOpen={showRefuseAction}
          onDidDismiss={() => setShowRefuseAction(false)}
          cssClass="action-sheet"
          buttons={[
            {
              cssClass: 'danger-action-sheet',
              text: 'Refuse',
              role: 'destructive',
              icon: removeCircle,
              handler: handleChangeStatus,
            },
            {
              text: 'Cancel',
              icon: close,
              role: 'cancel',
              handler: closeItemSliding,
            },
          ]}
        ></IonActionSheet>

        <IonActionSheet
          backdropDismiss={false}
          header="Souhaitez-vous accepter cette réservation ?"
          subHeader={customerName}
          isOpen={showAcceptAction}
          onDidDismiss={() => setShowAcceptAction(false)}
          buttons={[
            {
              cssClass: 'confirm-action-sheet',
              text: 'Accepter',
              icon: checkmarkCircle,
              handler: handleChangeStatus,
            },
            {
              text: 'Annuler',
              icon: close,
              role: 'cancel',
              handler: closeItemSliding,
            },
          ]}
        ></IonActionSheet>

        <IonActionSheet
          backdropDismiss={false}
          header="Souhaitez-vous asseoir cette réservation ?"
          subHeader={customerName}
          isOpen={showSeatAction}
          onDidDismiss={() => setShowSeatAction(false)}
          buttons={[
            {
              cssClass: 'confirm-action-sheet',
              text: 'Asseoir',
              icon: restaurant,
              handler: handleChangeStatus,
            },
            {
              text: 'Annuler',
              icon: close,
              role: 'cancel',
              handler: closeItemSliding,
            },
          ]}
        ></IonActionSheet>

        <IonActionSheet
          backdropDismiss={false}
          header="Souhaitez-vous terminer cette réservation ?"
          subHeader={customerName}
          isOpen={showFinishAction}
          onDidDismiss={() => setShowFinishAction(false)}
          buttons={[
            {
              cssClass: 'confirm-action-sheet',
              text: 'Terminer',
              icon: exitOutline,
              handler: handleChangeStatus,
            },
            {
              text: 'Annuler',
              icon: close,
              role: 'cancel',
              handler: closeItemSliding,
            },
          ]}
        ></IonActionSheet>

        <IonActionSheet
          backdropDismiss={false}
          header="Souhaitez-vous annuler cette réservation ?"
          subHeader={customerName}
          isOpen={showCancelAction}
          onDidDismiss={() => setShowCancelAction(false)}
          buttons={[
            {
              cssClass: 'danger-action-sheet',
              text: 'Annuler',
              icon: closeCircle,
              role: 'destructive',
              handler: handleChangeStatus,
            },
            {
              text: 'Annuler',
              icon: close,
              role: 'cancel',
              handler: closeItemSliding,
            },
          ]}
        ></IonActionSheet>

        <IonActionSheet
          backdropDismiss={false}
          header="Souhaitez-vous confirmer cette réservation ?"
          subHeader={customerName}
          isOpen={showConfirmAction}
          onDidDismiss={() => setShowConfirmAction(false)}
          buttons={[
            {
              cssClass: 'confirm-action-sheet',
              text: 'Confirmer',
              icon: checkmarkCircle,
              handler: handleChangeStatus,
            },
            {
              text: 'Annuler',
              icon: close,
              role: 'cancel',
              handler: closeItemSliding,
            },
          ]}
        ></IonActionSheet>

        <IonList>
          {fetching && !isReferesher ? (
            Array.from({ length: 10 }, (v, i) => (
              <IonItem key={i}>
                <IonLabel>
                  <h1>
                    <IonSkeletonText animated style={{ width: '90%' }} />
                  </h1>
                  <h2>
                    <IonSkeletonText animated style={{ width: '60%' }} />
                  </h2>
                  <p>
                    <IonSkeletonText animated style={{ width: '30%' }} />
                  </p>
                </IonLabel>
              </IonItem>
            ))
          ) : error ? (
            <IonItem>
              <IonLabel class="ion-text-center">
                <IonLabel>
                  <h1>Oups :( an error was generated</h1>
                </IonLabel>
                <IonLabel>
                  <h2>Please contact your admin or retry</h2>
                </IonLabel>
                <IonLabel>
                  <p>{error.message}</p>
                </IonLabel>
                <IonButton expand="block" onClick={refresh}>
                  Retry
                </IonButton>
              </IonLabel>
            </IonItem>
          ) : (
            data.bookings.edges.filter(searchBooking).map((item: Booking, index: number) => (
              /* @todo add group by hours */
              <IonItemSliding
                key={'booking_' + item.node.id}
                onIonDrag={(e) => setCustomerName(item.node.customer.name)}
              >
                <IonItem detail={false}>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="9">
                        <IonLabel>
                          <h2>
                            <IonText style={{ fontWeight: 600 }}>{item.node.customer.name}</IonText>
                          </h2>
                          {item.node.customer.telephone !== '0000000000' && (
                            <h3>
                              <IonText>{item.node.customer.telephone}</IonText>
                            </h3>
                          )}
                          <IonText>
                            {item.node.place.name} {item.node.zone && ' - ' + item.node.zone.name}
                          </IonText>
                          <IonText color="medium">
                            <h4>{item.node.comment}</h4>
                          </IonText>
                          {item.node.tags.length
                            ? item.node.tags.map((tag, index) => (
                                <IonText
                                  key={index}
                                  style={{ fontSize: '0.8rem', fontWeight: 600, color: tag.color }}
                                >
                                  {tag.name}
                                </IonText>
                              ))
                            : ''}
                          {/* <IonText color='danger' style={{fontSize: "0.8rem", fontWeight: 600}}> 
                              {item.node.tags.length ? (<BookingTagIcon key={index} icon={item.node.tags[0].icon} color={item.node.tags[0].color} />) : ''} {item.node.tags.length ? item.node.tags[0].name: ''}
                            </IonText> */}
                        </IonLabel>
                      </IonCol>
                      <IonCol size="1" className="ion-align-self-center">
                        {item.node.tags.length
                          ? item.node.tags.map((tag, index) => (
                              <BookingTagIcon key={index} icon={tag.icon} color={tag.color} />
                            ))
                          : ''}
                      </IonCol>
                      <IonCol size="2" className="ion-align-self-center">
                        <IonNote>
                          <IonLabel
                            style={{ textAlign: 'right', fontWeight: 500, fontSize: '1rem' }}
                            color="primary"
                          >
                            {item.node.time.substring(0, 5)}
                          </IonLabel>
                          <IonLabel style={{ textAlign: 'right' }}>
                            <IonBadge color="primary">{item.node.people}</IonBadge>
                            {/*
                                // @todo add partial accepted
                                index == 1 ? <IonBadge color='medium' >6 PAX</IonBadge> : <></>
                              */}
                          </IonLabel>
                        </IonNote>
                      </IonCol>
                      <IonCol style={{ display: bookingStatus === 'all' ? 'block' : 'none' }}>
                        <IonLabel
                          style={{ fontSize: '0.8rem', fontWeight: 700 }}
                          color={STATUS_COLOR[item.node.status.toLocaleLowerCase()]}
                        >
                          {item.node.status}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
                {ACTIONS[item.node.status.toLocaleLowerCase()].map((option: any, index: number) => (
                  <IonItemOptions side={option.side} key={'io' + item.node.status + index}>
                    <IonItemOption
                      color={option.color}
                      onClick={() =>
                        handleStatusBooking(item.node.id, option.status, item.node.customer.name)
                      }
                      type="button"
                      expandable
                    >
                      <IonGrid>
                        <IonRow>
                          <IonCol className="ion-align-self-center">
                            <IonIcon icon={option.icon} size="large" />
                          </IonCol>
                          <IonCol className="ion-align-self-center">
                            <IonLabel>{option.title}</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItemOption>
                  </IonItemOptions>
                ))}
              </IonItemSliding>
            ))
          )}
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default Booking
