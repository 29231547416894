import {
  IonCard,
  IonCardSubtitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react'
import './Counter.css'
import { arrowDownOutline, arrowUpOutline } from 'ionicons/icons'
interface CounterProps {
  link: string
  icon: string
  color: string
  label: string
  count: number
  count2: number | boolean
  percent?: number
}

const Counter: React.FC<CounterProps> = (props) => {
  return (
    <IonCard button type="button" className="counter" routerLink={props.link ? props.link : '#'}>
      <IonItem lines="none">
        <IonGrid>
          <IonRow>
            <IonCol size="6" className="ion-align-self-start">
              <IonLabel>
                <IonIcon slot="start" icon={props.icon} color={props.color} size="large" />
              </IonLabel>
            </IonCol>
            {props.percent && (
              <IonCol size="3" className="ion-align-self-center ion-text-end">
                <IonText color={props.percent > 0 ? 'success' : 'danger'}>
                  <IonIcon icon={props.percent > 0 ? arrowUpOutline : arrowDownOutline} />
                  {Math.abs(props.percent)}%
                </IonText>
              </IonCol>
            )}
            <IonCol size={props.percent ? '3' : '6'} className="ion-align-self-start">
              <IonLabel>{props.count}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size={props.count2 === false ? '12' : '7'} className="ion-align-self-end">
              <IonCardSubtitle color={props.color}>{props.label}</IonCardSubtitle>
            </IonCol>
            {props.count2 === false ? null : (
              <IonCol size="5" className="ion-align-self-end">
                <IonCardSubtitle>{props.count2} Resa</IonCardSubtitle>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonCard>
  )
}

export default Counter
