import { IonIcon } from "@ionic/react"
import { briefcase, diamond, gift, ribbon, warning } from "ionicons/icons"

interface TagProps {
    color: string,
    icon: string
}

const BookingTagIcon: React.FC<TagProps> = props => {
    let icon = '';

    switch (props.icon) {
        case "gift_fill":
            icon = gift
            break
        case "gem":
            icon = diamond
            break
        case "exclamation_triangle_fill":
            icon = warning
            break
        case "briefcase":
            icon = briefcase
            break
            case "award":
                icon = ribbon
                break
    }

    return <IonIcon icon={icon} style={{color: props.color, verticalAlign: "bottom", fontSize: "1.5rem"}} />
}

export default BookingTagIcon