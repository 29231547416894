import {
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonButton,
  IonButtons,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
  IonDatetime,
  IonModal,
  IonText,
  IonSelect,
  IonSelectOption,
  IonCard,
  IonItem,
  IonCardSubtitle,
  IonTitle,
} from '@ionic/react'
import {
  hourglassOutline,
  eyeOff,
  calendar,
  removeCircle,
  checkmarkCircle,
  bookmark,
  // restaurant,
  closeCircle,
  locationOutline,
  locationSharp,
  exit,
  enter,
} from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import AddBooking from '../components/AddBooking'
import Counter from '../components/Counter'
// import FastAdd from '../components/FastAdd'
import './Dashboard.css'

const Dashboard: React.FC = () => {
  const BOOKING_QUERY = `
  query ($date: Date!, $placeId: Int) {
    bookings(date: $date, placeId: $placeId) {
      bookingCount
      peopleCount
      peopleS1Count
      confirmedCount
      confirmedPeopleCount
      acceptedCount
      acceptedPeopleCount
      waitingCount
      waitingPeopleCount
      refusedCount
      refusedPeopleCount
      canceledCount
      canceledPeopleCount
      noShowCount
      noShowPeopleCount
      finishedCount
      finishedPeopleCount
      availableCount
      lunchCount
      lunchPeopleCount
      dinnerCount
      dinnerPeopleCount
      clubCount
      clubPeopleCount
      bookingCountByPlace {
        id
        name
        count
        people
        complex
      }
      bookingCountByComplex {
        id
        name
        count
        people
      }
    }
  }
  `

  const PLACE_QUERY = `
  query {
    places {
      edges {
        node {
          id
          name
        }
      }
    }
  }
  `

  const [currentDate, setCurrentDate] = useState(new Date())
  const [currentPlace, setCurrentPlace] = useState(null)

  const getIsoDate = () => {
    return currentDate.toISOString().split('T')[0]
  }

  const [result, reexecuteQuery] = useQuery({
    query: BOOKING_QUERY,
    variables: { date: currentDate.toISOString().split('T')[0], placeId: currentPlace },
  })

  const { data, fetching, error } = result
  const [isOpenDateTimeModal, setIsOpenDateTimeModal] = useState(false)

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    reexecuteQuery({ requestPolicy: 'network-only' })

    setTimeout(() => {
      event.detail.complete()
    }, 1000)
  }

  const isManager = localStorage.getItem('auth-group')?.includes('Manager')
  // const isHotess = localStorage.getItem('auth-group')?.includes('Hotess')

  const refresh = () => {
    reexecuteQuery({ requestPolicy: 'network-only' })
  }

  const handleChangeDate = (e: any) => {
    setCurrentDate(new Date(e.detail.value))
    setIsOpenDateTimeModal(false)
    e.target.reset()
  }

  // useEffect(() => {
  //   const timerId = setTimeout(() => {
  //     reexecuteQuery({ requestPolicy: 'network-only' });
  //   }, 10000);

  //   return () => {
  //     clearTimeout(timerId)
  //   }
  // }, [result.fetching, reexecuteQuery]);

  const [stats, setStats] = useState<any>({
    bookingCount: 0,
    peopleCount: 0,
    peopleS1Count: 0,
    confirmedCount: 0,
    confirmedPeopleCount: 0,
    acceptedCount: 0,
    acceptedPeopleCount: 0,
    // seatedCount: 0,
    // seatedPeopleCount: 0,
    refusedCount: 0,
    refusedPeopleCount: 0,
    canceledCount: 0,
    canceledPeopleCount: 0,
    availableCount: 0,
    finishedCount: 0,
    finishedPeopleCount: 0,
    waitingCount: 0,
    waitingPeopleCount: 0,
    noShowCount: 0,
    noShowPeopleCount: 0,
    lunchCount: 0,
    lunchPeopleCount: 0,
    dinnerCount: 0,
    dinnerPeopleCount: 0,
    clubCount: 0,
    clubPeopleCount: 0,
    bookingCountByPlace: [],
    bookingCountByComplex: [],
  })

  useEffect(() => {
    if (fetching) return

    const bookings = data.bookings

    setStats({
      bookingCount: bookings.bookingCount,
      peopleCount: bookings.peopleCount,
      peopleS1Count: bookings.peopleS1Count,
      confirmedCount: bookings.confirmedCount,
      confirmedPeopleCount: bookings.confirmedPeopleCount,
      acceptedCount: bookings.acceptedCount,
      acceptedPeopleCount: bookings.acceptedPeopleCount,
      // seatedCount: bookings.seatedCount,
      // seatedPeopleCount: bookings.seatedPeopleCount,
      refusedCount: bookings.refusedCount,
      refusedPeopleCount: bookings.refusedPeopleCount,
      canceledCount: bookings.canceledCount,
      canceledPeopleCount: bookings.canceledPeopleCount,
      finishedCount: bookings.finishedCount,
      finishedPeopleCount: bookings.finishedPeopleCount,
      waitingCount: bookings.waitingCount,
      waitingPeopleCount: bookings.waitingPeopleCount,
      noShowCount: bookings.noShowCount,
      noShowPeopleCount: bookings.noShowPeopleCount,
      availableCount: bookings.availableCount,
      lunchCount: bookings.lunchCount,
      lunchPeopleCount: bookings.lunchPeopleCount,
      dinnerCount: bookings.dinnerCount,
      dinnerPeopleCount: bookings.dinnerPeopleCount,
      clubCount: bookings.clubCount,
      clubPeopleCount: bookings.clubPeopleCount,
      bookingCountByPlace: bookings.bookingCountByPlace,
      bookingCountByComplex: bookings.bookingCountByComplex,
    })
  }, [fetching, data])

  const [placeResult] = useQuery({
    query: PLACE_QUERY,
  })

  const calculPercent = (v1: number, v2: number) => {
    if (v2 === 0) return 100

    return Math.round((v1 / v2 - 1) * 100)
  }

  return (
    <IonPage className="dashboard">
      <IonHeader collapse="fade">
        <IonToolbar>
          <IonSelect
            interface="action-sheet"
            slot="start"
            placeholder="Tous les restaurants"
            onIonChange={(e) => setCurrentPlace(e.detail.value)}
          >
            <IonSelectOption value={null}>Tous les restaurants</IonSelectOption>
            {!placeResult.fetching &&
              placeResult.data.places.edges.map((item: any, index: any) => (
                <IonSelectOption key={index} value={item.node.id}>
                  {item.node.name}
                </IonSelectOption>
              ))}
          </IonSelect>

          <IonButtons slot="end">
            <IonButton onClick={(e) => setIsOpenDateTimeModal(true)} className="ion-padding-end">
              <IonText className="ion-padding-end">{currentDate.toLocaleDateString()}</IonText>
              <IonIcon icon={calendar} />
            </IonButton>
          </IonButtons>

          <IonModal isOpen={isOpenDateTimeModal} className="date-modal" backdropDismiss={false}>
            <IonContent forceOverscroll={false}>
              <IonDatetime
                presentation="date"
                onIonChange={handleChangeDate}
                value={currentDate.toISOString()}
                size="cover"
              />
            </IonContent>
          </IonModal>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/* {isHotess ? <FastAdd /> : ''} */}
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonGrid>
          {error ? (
            <IonRow>
              <IonCol size="12">
                <IonLabel class="ion-text-center">
                  <IonLabel>
                    <h1>Oups :( an error was generated</h1>
                  </IonLabel>
                  <IonLabel>
                    <h2>Please contact your admin or retry</h2>
                  </IonLabel>
                  {error.graphQLErrors.map((item: any, index: number) => (
                    <IonLabel key={index}>
                      <p>{item.message}</p>
                    </IonLabel>
                  ))}
                  <IonButton expand="block" onClick={refresh}>
                    Retry
                  </IonButton>
                </IonLabel>
              </IonCol>
            </IonRow>
          ) : (
            <IonRow>
              <IonCol size="12" sizeSm="4" sizeLg="3">
                <Counter
                  color="primary"
                  link={'/booking/all/' + getIsoDate() + '/' + currentPlace}
                  icon={calendar}
                  count={stats.peopleCount}
                  count2={stats.bookingCount}
                  percent={calculPercent(stats.peopleCount, stats.peopleS1Count)}
                  label="Total des réservations"
                />
              </IonCol>

              <IonCol size="12" sizeSm="4" sizeLg="3">
                <IonCard className="counter">
                  <IonItem lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <IonLabel>{stats.lunchPeopleCount}</IonLabel>
                        </IonCol>
                        <IonCol className="ion-text-center">
                          <IonLabel>{stats.dinnerPeopleCount}</IonLabel>
                        </IonCol>
                        <IonCol className="ion-text-center">
                          <IonLabel>{stats.clubPeopleCount}</IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <IonCardSubtitle>{stats.lunchCount} Resa</IonCardSubtitle>
                        </IonCol>
                        <IonCol className="ion-text-center">
                          <IonCardSubtitle>{stats.dinnerCount} Resa</IonCardSubtitle>
                        </IonCol>
                        <IonCol className="ion-text-center">
                          <IonCardSubtitle>{stats.clubCount} Resa</IonCardSubtitle>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <IonCardSubtitle color="primary">Déjeuner</IonCardSubtitle>
                        </IonCol>
                        <IonCol className="ion-text-center">
                          <IonCardSubtitle color="secondary">Diner</IonCardSubtitle>
                        </IonCol>
                        <IonCol className="ion-text-center">
                          <IonCardSubtitle color="tertiary">Club</IonCardSubtitle>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>

              <IonCol size="12">
                <IonHeader collapse="condense">
                  <IonToolbar>
                    <IonTitle>Restaurants</IonTitle>
                  </IonToolbar>
                </IonHeader>
              </IonCol>

              {/*
              {stats.bookingCountByComplex.map((complex: any, index: any) => (
                <IonCol size="12" sizeSm="4" sizeLg="3" key={index}>
                  <Counter
                    color="primary"
                    link={'/booking/all/' + getIsoDate() + '/0/' + complex.id + '/' + complex.name}
                    icon={locationSharp}
                    count={complex.people}
                    count2={complex.count}
                    label={complex.name}
                  />
                </IonCol>
              ))}
              */}

              {stats.bookingCountByPlace
                .filter((c: any) => c.complex === 'La Cascade')
                .map((place: any, index: any) => (
                  <IonCol size="6" sizeSm="4" sizeLg="3" key={index}>
                    <Counter
                      color="secondary"
                      link={'/booking/all/' + getIsoDate() + '/' + place.id + '/0/' + place.name}
                      icon={locationOutline}
                      count={place.people}
                      count2={place.count}
                      label={place.name}
                    />
                  </IonCol>
                ))}

              <IonCol size="12">
                <IonHeader collapse="condense">
                  <IonToolbar>
                    <IonTitle>Statuts</IonTitle>
                  </IonToolbar>
                </IonHeader>
              </IonCol>

              <IonCol size="6" sizeSm="4" sizeLg="3">
                <Counter
                  color="primary"
                  link={'/booking/confirmed/' + getIsoDate() + '/' + currentPlace}
                  icon={checkmarkCircle}
                  count={stats.confirmedPeopleCount}
                  count2={stats.confirmedCount}
                  label="Confirmés"
                />
              </IonCol>

              <IonCol size="6" sizeSm="4" sizeLg="3">
                <Counter
                  color="success"
                  link={'/booking/accepted/' + getIsoDate() + '/' + currentPlace}
                  icon={enter}
                  count={stats.acceptedPeopleCount}
                  count2={stats.acceptedCount}
                  label="Acceptés"
                />
              </IonCol>

              {/* <IonCol size="6" sizeSm="4" sizeLg="3">
                <Counter
                  color="success"
                  link={'/booking/seated/' + getIsoDate() + '/' + currentPlace}
                  icon={restaurant}
                  count={stats.seatedCount}
                  count2={stats.seatedPeopleCount}
                  label="Seated"
                />
              </IonCol> */}

              <IonCol size="6" sizeSm="4" sizeLg="3">
                <Counter
                  color="danger"
                  link={'/booking/refused/' + getIsoDate() + '/' + currentPlace}
                  icon={removeCircle}
                  count={stats.refusedPeopleCount}
                  count2={stats.refusedCount}
                  label="Refusés"
                />
              </IonCol>

              <IonCol size="6" sizeSm="4" sizeLg="3">
                <Counter
                  color="warning"
                  link={'/booking/waiting/' + getIsoDate() + '/' + currentPlace}
                  icon={hourglassOutline}
                  count={stats.waitingPeopleCount}
                  count2={stats.waitingCount}
                  label="En Attente"
                />
              </IonCol>

              <IonCol size="6" sizeSm="4" sizeLg="3">
                <Counter
                  color="medium"
                  link={'/booking/no_show/' + getIsoDate() + '/' + currentPlace}
                  icon={eyeOff}
                  count={stats.noShowPeopleCount}
                  count2={stats.noShowCount}
                  label="No Show"
                />
              </IonCol>

              <IonCol size="6" sizeSm="4" sizeLg="3">
                <Counter
                  color="medium"
                  link={'/booking/canceled/' + getIsoDate() + '/' + currentPlace}
                  icon={closeCircle}
                  count={stats.canceledPeopleCount}
                  count2={stats.canceledCount}
                  label="Annulé"
                />
              </IonCol>

              <IonCol size="6" sizeSm="4" sizeLg="3">
                <Counter
                  color="secondary"
                  link=""
                  icon={bookmark}
                  count={stats.availableCount}
                  count2={false}
                  label="Places disponible"
                />
              </IonCol>

              <IonCol size="6" sizeSm="4" sizeLg="3">
                <Counter
                  color="dark"
                  link={'/booking/finished/' + getIsoDate() + '/' + currentPlace}
                  icon={exit}
                  count={stats.finishedPeopleCount}
                  count2={stats.finishedCount}
                  label="Terminés"
                />
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
      <IonFooter collapse="fade" translucent>
        {isManager ? <AddBooking /> : ''}
      </IonFooter>
    </IonPage>
  )
}

export default Dashboard
