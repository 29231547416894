import { Redirect, Route, RouteProps } from 'react-router-dom'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import Booking from './pages/Booking'
import { createClient, Provider } from 'urql'

import Login from './pages/Login'
import Profile from './pages/Profile'
import Dashboard from './pages/Dashboard'

const urqlClient = createClient({
  url: 'https://api.lacascade.qlikdata.io/',
  // url: 'http://172.20.10.5:8000/',
  // url: 'http://localhost:8000/',
  // url: 'http://192.168.1.101:8000/',
  fetchOptions: () => {
    return {
      headers: {
        authorization: localStorage.getItem('auth-token')
          ? `Bearer ${localStorage.getItem('auth-token')}`
          : '',
      },
    }
  },
})

setupIonicReact()

export type ProtectedRouteProps = {
  authenticationPath: string
} & RouteProps

const PrivateRoute: React.FC<ProtectedRouteProps> = (props) => {
  const isAuthenticated = !!localStorage.getItem('auth-token')

  if (isAuthenticated) return <Route {...props} />

  return <Redirect to="/login" />
}

const App: React.FC = () => {
  // const isAuthenticated = !!localStorage.getItem('auth-token')

  return (
    <IonApp>
      <Provider value={urqlClient}>
        <IonReactRouter>
          <IonRouterOutlet>
            {/* <Route exact path="/login" render={(props) => {return isAuthenticated ? <Dashboard /> : <Login /> }} /> */}
            <Route exact path="/login" component={Login} />
            <PrivateRoute authenticationPath="/login" exact path="/">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute
              authenticationPath="/login"
              exact
              path="/booking/:bookingStatus/:date/:placeId/:complexId?/:pageTitle?"
            >
              <Booking />
            </PrivateRoute>
            <PrivateRoute authenticationPath="/login" exact path="/profile">
              <Profile />
            </PrivateRoute>
          </IonRouterOutlet>
        </IonReactRouter>
      </Provider>

      {/* <Dashboard2 /> */}
    </IonApp>
  )
}

export default App
