import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useHistory } from 'react-router'

const Profile: React.FC = () => {
  const history = useHistory()

  const logout = () => {
    localStorage.removeItem('auth-token')
    localStorage.removeItem('auth-username')
    localStorage.removeItem('auth-group')
    history.push('/login')
    document.location.reload()
  }

  const goToDashboard = () => {
    history.push('/')
    document.location.reload()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{localStorage.getItem('auth-username')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton onClick={(e) => goToDashboard()} size="large">
                Go to Dashboard
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton color="danger" onClick={(e) => logout()} size="large">
                Logout
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Profile
