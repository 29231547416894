import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from '@ionic/react'
import { addCircle, alertCircle } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'urql'
import './AddBooking.css'

const AddBooking = () => {
  const initialFormValue = {
    people: '',
    date: '',
    time: '',
    telephone: '',
    name: '',
    email: '',
    tags: [],
    comment: '',
  }

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [formValue, setFormValue] = useState<any>(initialFormValue)

  const [presentToast] = useIonToast()
  const [presentAlert] = useIonAlert()

  const [isPause, setIsPause] = useState(true)
  const QUERY_BOOKING = `
        query ($telephone: String!) {
            customers (telephone: $telephone) {
                edges {
                    node {
                        name
                    }
                }
            }
        }
    `

  const QUERY_TAGS = `
    query {
        tags {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `

  const QUERY_PLACE = `
    query {
        places {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `

  const [result] = useQuery({
    pause: isPause,
    query: QUERY_BOOKING,
    variables: { telephone: formValue.telephone },
  })
  const { data } = result

  const [tagResult] = useQuery({
    query: QUERY_TAGS,
  })

  const [placeResult] = useQuery({
    query: QUERY_PLACE,
  })

  const searchByTelephone = () => {
    if (formValue.telephone.length < 10) return

    const edges = data.customers.edges
    if (edges.length === 0) return

    setFormValue((prevState: any) => ({ ...prevState, name: edges[0].node.name }))
  }

  useEffect(() => {
    if (formValue.telephone.length < 10) return

    setIsPause(false)
  }, [formValue])

  const checkInput = () => {
    let error = false
    document.querySelectorAll('ion-input[required]').forEach((e: any) => {
      if (e.value !== '') {
        e.parentElement!.classList.remove('error')
        return
      }

      error = true
      e.parentElement!.classList.add('error')
    })

    return error
  }

  const handleClose = () => {
    setFormValue(initialFormValue)
    setHasError(false)
    setIsOpen(false)
    setIsLoading(false)
  }

  const MUTATION_BOOKING = `
        mutation (
            $people: Int!, 
            $date: Date!, 
            $time: Time!, 
            $telephone: String!,
            $name: String!,
            $email: String!,
            $comment: String,
            $place: Int!
            $tags: [Int]
        ) {
            createBooking (
                people: $people,
                date: $date, 
                time: $time, 
                telephone: $telephone, 
                name: $name,
                email: $email, 
                comment: $comment
                place: $place
                tags: $tags
            ) {
                booking {
                    id
                    customer {
                        name
                        email
                    }
                }
            }
        }
    `
  const [state, executeMutation] = useMutation(MUTATION_BOOKING)
  const handleCreateBooking = () => {
    setIsLoading(true)

    if (checkInput()) {
      setHasError(true)
      setIsLoading(false)
      return
    }

    executeMutation(formValue).then((result) => {
      if (result.error) {
        setIsLoading(false)
        presentAlert({
          message: result.error.graphQLErrors[0].message,
          buttons: [{ text: 'OK', role: 'cancel' }],
        })

        return
      }

      presentToast({ message: 'The booking has been created', color: 'success', duration: 1000 })
      handleClose()
    })
  }

  return (
    <IonToolbar>
      <IonModal isOpen={isOpen}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Ajouter une réservation</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={handleClose}>Fermer</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel position="floating">Restaurant</IonLabel>
              <IonSelect
                value={formValue.place}
                okText="OK"
                cancelText="Cancel"
                onIonChange={(e) =>
                  setFormValue((prevState: any) => ({ ...prevState, place: e.detail.value }))
                }
              >
                {placeResult.fetching ? (
                  <option>Loading ....</option>
                ) : (
                  placeResult.data.places.edges.map((place: any, index: number) => (
                    <IonSelectOption key={index} value={place.node.id}>
                      {place.node.name}
                    </IonSelectOption>
                  ))
                )}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">PAX</IonLabel>
              <IonInput
                required
                type="number"
                inputMode="tel"
                value={formValue.people}
                onIonChange={(v) =>
                  setFormValue((prevState: any) => ({ ...prevState, people: v.detail.value }))
                }
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Date de la réservation</IonLabel>
              <IonInput
                required
                type="date"
                value={formValue.date}
                onIonChange={(v) =>
                  setFormValue((prevState: any) => ({ ...prevState, date: v.detail.value }))
                }
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Heure de la réservation</IonLabel>
              <IonInput
                required
                type="time"
                value={formValue.time}
                onIonChange={(v) =>
                  setFormValue((prevState: any) => ({ ...prevState, time: v.detail.value }))
                }
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Téléphone</IonLabel>
              <IonInput
                pattern="[0-9]{2}"
                required
                type="number"
                inputMode="tel"
                value={formValue.telephone}
                onIonChange={(v) =>
                  setFormValue((prevState: any) => ({ ...prevState, telephone: v.detail.value }))
                }
                onIonBlur={(e) => searchByTelephone()}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Nom et Prénom</IonLabel>
              <IonInput
                required
                clearInput
                type="text"
                value={formValue.name}
                onIonChange={(e) =>
                  setFormValue((prevState: any) => ({ ...prevState, name: e.detail.value }))
                }
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Catégorie</IonLabel>
              <IonSelect
                value={formValue.tags}
                okText="OK"
                cancelText="Cancel"
                onIonChange={(e) =>
                  setFormValue((prevState: any) => ({ ...prevState, tags: e.detail.value }))
                }
              >
                {tagResult.fetching ? (
                  <option>Chargement ....</option>
                ) : (
                  tagResult.data.tags.edges.map((tag: any, index: number) => (
                    <IonSelectOption key={'tag' + index} value={tag.node.id}>
                      {tag.node.name}
                    </IonSelectOption>
                  ))
                )}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Adresse e-mail</IonLabel>
              <IonInput
                type="text"
                inputMode="email"
                value={formValue.email}
                onIonChange={(v) =>
                  setFormValue((prevState: any) => ({ ...prevState, email: v.detail.value }))
                }
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Note</IonLabel>
              <IonTextarea
                value={formValue.comment}
                onIonChange={(v) =>
                  setFormValue((prevState: any) => ({ ...prevState, comment: v.detail.value }))
                }
              />
            </IonItem>
            <IonItem lines="none" style={{ display: hasError ? 'block' : 'none' }}>
              <IonIcon icon={alertCircle} style={{ fontSize: '1.8rem' }} color="danger" />
              <IonLabel color="danger" style={{ fontWeight: 500 }}>
                Merci de saisir toutes les informations
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter collapse="fade" translucent>
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton size="large" expand="block" fill="clear" onClick={handleClose}>
                    Annuler
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand="block"
                    onClick={handleCreateBooking}
                    size="large"
                    type="submit"
                  >
                    <IonSpinner style={{ display: isLoading ? 'block' : 'none' }} />
                    <IonText style={{ display: isLoading ? 'none' : 'block' }}>Ajouter</IonText>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      <IonButton fill="clear" size="large" onClick={(e) => setIsOpen(true)}>
        <IonIcon slot="icon-only" icon={addCircle} color="primary" size="large" />
        <span style={{ fontSize: '1rem', fontWeight: 700, paddingLeft: 10 }}>
          Ajouter une réservation
        </span>
      </IonButton>
    </IonToolbar>
  )
}

export default AddBooking
